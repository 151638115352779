/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

// import Header from "./header"
import Nav from "./nav"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      {/*<Header siteTitle={data.site.siteMetadata?.title || `Title`} />*/}
      <div className="bg-gray-800 text-pink-500 text-2xl py-5">
        <a className="flex items-center" href="https://pearceiplawyers.sharepoint.com/">
          <svg className="mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0H24V24H0z"/><path d="M11 20L1 12l10-8v5c5.523 0 10 4.477 10 10 0 .273-.01.543-.032.81C19.46 16.95 16.458 15 13 15h-2v5z" fill="rgba(244,114,182,1)"/></svg>

          Back to Intranet
        </a>
      </div>

      <div
        style={{
          margin: `0 auto`,
          maxWidth: `100%`,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        

        <div className="grid sm:grid-cols-4">
          <div className="sm:col-span-1">
            <Nav />
          </div>

          <div className="sm:col-span-3 pl-5">
            <main>{children}</main>
          </div>
        </div>

        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
