import React from 'react'
import { Link } from 'gatsby'

const Nav = () => {
	return (
		<>
			<div className="title text-3xl">Resource</div>
			<ul className="nav">
				<li><Link activeClassName="active" to="/online-news/">ONLINE NEWS</Link></li>
				<li><Link activeClassName="active" to="/helpful-links/">HELPFUL LINKS</Link></li>
                <li><Link activeClassName="active" to="/company-searches/">COMPANY SEARCHES</Link></li>
                <li><Link activeClassName="active" to="/subscription-resources/">SUBSCRIPTION RESOURCES</Link></li>
                <li><Link activeClassName="active" to="/australian-resources/">AUSTRALIAN RESOURCES</Link></li>
				<li><Link activeClassName="active" to="/patent-prior-art-searching/">PATENT / PRIOR ART SEARCHING</Link></li>
				<li><Link activeClassName="active" to="/international-ip-resources/">INTERNATIONAL IP RESOURCES</Link></li>
				<li><Link activeClassName="active" to="/united-states-resources/">UNITED STATES RESOURCES</Link></li>
				<li><Link activeClassName="active" to="/epo-eu-epc/">EPO/ AND EU/EPC</Link></li>
				<li><Link activeClassName="active" to="/other-epc-countries/">OTHER EPC COUNTRIES</Link></li>
				<li><Link activeClassName="active" to="/united-kingdom/">UNITED KINGDOM</Link></li>
				<li><Link activeClassName="active" to="/new-zealand/">NEW ZEALAND</Link></li>
				<li><Link activeClassName="active" to="/canada/">CANADA</Link></li>
				<li><Link activeClassName="active" to="/asia/">ASIA</Link></li>
				<li><Link activeClassName="active" to="/russia/">RUSSIA</Link></li>
				<li><Link activeClassName="active" to="/israel/">ISRAEL</Link></li>
				<li><Link activeClassName="active" to="/life-sciences-resources/">LIFE SCIENCES RESOURCES</Link></li>
			</ul>
		</>
	)	
}

export default Nav